import httpFile from './http.file'
import http from './http'
const mainUri = '/api/v2/arrest-report-vehicle'
const bureauUri = '/api/v2/arrest-report-vehicle-bureau'

export function list(params) {
  return http({
    url: `/api/v2/arrest-report-vehicle`,
    method: 'get',
    params: params,
  })
}

export function listBureau(params) {
  return http({
    url: `/api/v2/arrest-report-vehicle-bureau`,
    method: 'get',
    params: params,
  })
}

export function listDivision(params) {
  return http({
    url: `/api/v2/arrest-report-vehicle-division`,
    method: 'get',
    params: params,
  })
}

export function listStation(params) {
  return http({
    url: `/api/v2/arrest-report-vehicle-station`,
    method: 'get',
    params: params,
  })
}

export function exportExcel(params) {
  return http({
    url: `/api/v2/arrest-report-vehicle-export`,
    method: 'get',
    params: params
  }, {responseType: 'blob'})
  .then(res => {
    const url = window.URL.createObjectURL(new Blob([res]));
    const a = document.createElement("a");
    a.href = url;
    const filename = `report_vehicle.csv`;
    a.setAttribute('download', filename);
    a.click();
    a.remove();
  })
}

export function exportExcelBureau(params) {
  return http({
    url: `/api/v2/arrest-report-vehicle-bureau-export`,
    method: 'get',
    params: params
  }, {responseType: 'blob'})
  .then(res => {
    const url = window.URL.createObjectURL(new Blob([res]));
    const a = document.createElement("a");
    a.href = url;
    const filename = `report_vehicle.csv`;
    a.setAttribute('download', filename);
    a.click();
    a.remove();
  })
}

export function exportExcelDivision(params) {
  return http({
    url: `/api/v2/arrest-report-vehicle-division-export`,
    method: 'get',
    params: params
  }, {responseType: 'blob'})
  .then(res => {
    const url = window.URL.createObjectURL(new Blob([res]));
    const a = document.createElement("a");
    a.href = url;
    const filename = `report_vehicle.csv`;
    a.setAttribute('download', filename);
    a.click();
    a.remove();
  })
}

export function exportExcelStation(params) {
  return http({
    url: `/api/v2/arrest-report-vehicle-station-export`,
    method: 'get',
    params: params
  }, {responseType: 'blob'})
  .then(res => {
    const url = window.URL.createObjectURL(new Blob([res]));
    const a = document.createElement("a");
    a.href = url;
    const filename = `report_vehicle.csv`;
    a.setAttribute('download', filename);
    a.click();
    a.remove();
  })
}
