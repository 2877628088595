<template>
  <div id="arrestedVehicleTable">
    <v-data-table
      ref="atable"
      :headers="headers"
      :loading="loading"
      :items="lists"
      :options.sync="options"
      class="elevation-1 row-pointer"
      :footer-props="footer"
    >
      <template v-slot:[`item.created_at`]="{ item }">
        {{
          moment(item.created_at)
            .add(543, 'year')
            .format('D MMMYYYY เวลา HH:mm')
        }}
      </template>
      <template v-slot:[`item.fullname`]="{ item }">
        {{ item.fullname || 'ไม่ระบุ' }}
      </template>
      <template v-slot:[`item.car_total`]="{ item }">
        <span style="font-weight: 600;">{{ commafy(item.car_total) || '' }}</span>
      </template>
      <template v-slot:[`item.motorcycle_total`]="{ item }">
        <span style="font-weight: 600;">{{ commafy(item.motorcycle_total) || '' }}</span>
      </template>
      <template v-slot:[`item.car_arrested`]="{ item }">
        <span style="color: red; font-weight: 600;">{{ commafy(item.car_arrested) || '' }}</span>
      </template>
      <template v-slot:[`item.motorcycle__arrested`]="{ item }">
        <span style="color: red; font-weight: 600;">{{ commafy(item.motorcycle__arrested) || '' }}</span>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-speed-dial direction="left" open-on-hover>
          <v-btn color="blue darken-4" icon slot="activator" small dark fab>
            <v-icon>mdi-menu</v-icon>
          </v-btn>
          <v-btn x-small fab @click.stop="rowClick(item)" color="blue" dark>
            <v-icon small>mdi-eye</v-icon>
          </v-btn>
          <!-- <v-btn x-small fab @click.stop="$emit('row-dbl-click', item.id)" color="blue" dark v-if="item.can.update">
            <v-icon small>mdi-pen</v-icon>
          </v-btn>
          <v-btn x-small fab @click.stop="removeData(item.id)" color="red" dark v-if="item.can.delete">
            <v-icon small>mdi-delete</v-icon>
          </v-btn>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn x-small fab color="grey" dark @click="$emit('logs', item.id)" v-on="on" v-bind="attrs">
                <v-icon small>mdi-playlist-check</v-icon>
              </v-btn>
            </template>
            <span>Change Logs</span>
          </v-tooltip> -->
        </v-speed-dial>
      </template>
    </v-data-table>
    <div id="print" ref="pp" class="d-none"></div>
  </div>
</template>

<script>
import { list, listBureau, listDivision, listStation } from '@/api/arrestedvehiclereport'
import { mapGetters } from 'vuex'
export default {
  methods: {
    getList() {
      const { page, itemsPerPage } = this.options
      const param = {
        start: this.dateStart,
        end: this.dateEnd,
      }

        this.loading = true
          list(param)
          .then(res => {
            this.lists = res
            this.$emit('on-list-updated', res.data)
          })
          .finally(() => {
            this.loading = false
          })
    },
    getListBureau(v) {
      const { page, itemsPerPage } = this.options
      const param = {
        bureau: this.bureauId || v,
        start: this.dateStart,
        end: this.dateEnd,
      }
      if (this.bureauId || v) {
        this.loading = true
        listBureau(param)
        .then(res => {
          this.lists = res
          this.$emit('on-list-updated', res.data)
        })
        .finally(() => {
          this.loading = false
        })
      } else {
        this.loading = true
        list(param)
        .then(res => {
          this.lists = res
          this.$emit('on-list-updated', res.data)
        })
        .finally(() => {
          this.loading = false
        })
      }
      
    },
    getListDivision(v) {
      const { page, itemsPerPage } = this.options
      const param = {
        division: this.divisionId || v,
        bureau: this.bureauId,
        start: this.dateStart,
        end: this.dateEnd,
      }
      if (this.divisionId || v) {
        this.loading = true
        listDivision(param)
        .then(res => {
          this.lists = res
          this.$emit('on-list-updated', res.data)
        })
        .finally(() => {
          this.loading = false
        })
      } else {
        this.loading = true
        listBureau(param)
        .then(res => {
          this.lists = res
          this.$emit('on-list-updated', res.data)
        })
        .finally(() => {
          this.loading = false
        })
      }
      
    },
    getListStation(v) {
      const { page, itemsPerPage } = this.options
      const param = {
        station: this.stationId || v,
        division: this.divisionId,
        start: this.dateStart,
        end: this.dateEnd,
      }
      if (this.stationId || v) {
        this.loading = true
        listStation(param)
        .then(res => {
          this.lists = res
          this.$emit('on-list-updated', res.data)
        })
        .finally(() => {
          this.loading = false
        })
      } else {
        this.loading = true
        listDivision(param)
        .then(res => {
          this.lists = res
          this.$emit('on-list-updated', res.data)
        })
        .finally(() => {
          this.loading = false
        })
      }
      
    },
    rowClick(row) {
      this.$emit('row-click', row)
    },
    print() {
      var x = this.$refs.atable.$children[0].$el.children
      x = x[0]
      this.$refs.pp.innerHTML = x.innerHTML
      this.$htmlToPaper('print')
    },
    rightClick(e, data) {
      e.preventDefault()
      this.$emit('row-click', data.item)
    },
    rowDblClick(e, data) {
      if (!data.item.can.update) return
      this.$emit('row-dbl-click', data.item.id)
    },
    removeData(id) {
      this.$emit('remove-user', id)
    },
    commafy( num ) {
      return num?.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
    }
  },
  props: {
    keyword: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: '',
    },
    showArrestOnly: {},
    showWarnOnly: {},
  },
  watch: {
    options: {
      handler() {
        this.loading = true
        if (this.roleCode === 'station-admin') {
          this.getListStation(this.station_id)
        } else if (this.roleCode === 'division-admin') {
          this.getListDivision(this.division_id)
        } else if (this.roleCode === 'bureau-admin') {
          this.getListBureau (this.bureau_id)
        } else {
          if (this.stationId) {
            this.getListStation()
          } else if (this.divisionId) {
            this.getListDivision()
          } else if (this.bureauId) {
            this.getListBureau()
          } else {
            this.getList()
          }
        }
      },
      deep: true,
    },
    bureauId(v) {
      this.getListBureau()
    },
    divisionId(v) {
      this.getListDivision()
    },
    stationId(v) {
      this.getListStation()
    },
    datesSelected(v) {
      if (this.dateStart && this.dateEnd) {
        if (this.roleCode === 'station-admin') {
          this.getListStation(this.station_id)
        } else if (this.roleCode === 'division-admin') {
          this.getListDivision(this.division_id)
        } else if (this.roleCode === 'bureau-admin') {
          this.getListBureau (this.bureau_id)
        } else {
          if (this.stationId) {
            this.getListStation()
          } else if (this.divisionId) {
            this.getListDivision()
          } else if (this.bureauId) {
            this.getListBureau()
          } else {
            this.getList()
          }
        }
      }
    },
    showArrestOnly() {
      // this.getList()
    },
    showWarnOnly() {
      // this.getList()
    },
  },
  created() {
    if (this.station?.id) {
      localStorage.setItem('station_id', this.station?.id)
      localStorage.setItem('division_id', this.station?.division_id)
      localStorage.setItem('bureau_id', this.station?.bureau_id)
      localStorage.setItem('roleCode', this.role)
    }

    this.station_id = this.station?.id || localStorage.getItem('station_id')
    this.division_id = this.station?.division_id || localStorage.getItem('division_id')
    this.bureau_id = this.station?.bureau_id || localStorage.getItem('bureau_id')
    this.roleCode = this.role || localStorage.getItem('roleCode')

    if (this.roleCode === 'station-admin') {
      this.getListStation(this.station_id)
    } else if (this.roleCode === 'division-admin') {
      this.getListDivision(this.division_id)
    } else if (this.roleCode === 'bureau-admin') {
      this.getListBureau (this.bureau_id)
    } else {
      if (this.stationId) {
        this.getListStation()
      } else if (this.divisionId) {
        this.getListDivision()
      } else if (this.bureauId) {
        this.getListBureau()
      } else {
        this.getList()
      }
    }
  },
  data() {
    return {
      loading: true,
      headers: [
        {
          text: 'หน่วยงาน',
          sortable: false,
          value: 'name_abbr',
        },
        {
          text: 'จำนวนรถยนต์ที่เรียกตรวจ',
          sortable: false,
          value: 'car_total',
        },
        {
          text: 'จำนวนรถยนต์ที่พบการกระทำความผิด',
          sortable: false,
          value: 'car_arrested',
        },
        {
          text: 'จำนวนรถจยย.ที่เรียกตรวจ',
          sortable: false,
          value: 'motorcycle_total',
        },
        {
          text: 'จำนวนรถจยย.ที่พบการกระทำความผิด',
          sortable: false,
          value: 'motorcycle__arrested',
        },

      ],
      footer: {
        itemsPerPageOptions: [],
        showCurrentPage: false,
        showFirstLastPage: false,
        showItemsPerPage: false,
      },
      // tableMeta: {
      //   itemsPerPage: 1000,
      //   per_page: 1000,
      //   page: 1,
      //   total: 0,
      // },
      lists: [],
      options: {
        itemsPerPage: 1000,
        disableItemsPerPage: true,
      },
      station_id: null,
      division_id: null,
      bureau_id: null,
      roleCode: null
    }
  },
  computed: {
    ...mapGetters('Appfilter', [
      'bureaus',
      'bureauId',
      'divisionId',
      'stationId',
      'divisions',
      'stations',
      'dateStart',
      'dateEnd',
      'datesSelected',
    ]),
    ...mapGetters('User', ['name', 'position', 'role', 'avatarPicture', 'station', 'phone', 'level_name']),
  },
}
</script>

<style lang="css" scoped>
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
</style>
